<template>
  <div>
    <Modal v-model="showResource" width="1200" title="添加资源到媒体库" footer-hide>
      <Row :gutter="6" class="m-b-10">
        <i-col :xs="24" :sm="12" :md="6" :lg="3" class="m-b-5">
          <i-select size="small" v-model="assetId" @on-change="handleChangeAsset">
            <Option :value="-1">全部资产</Option>
            <i-option :value="item.id" v-for="item in assetArray" :key="item.id">{{ item.name }}</i-option>
          </i-select>
        </i-col>
        <i-col :xs="24" :sm="12" :md="6" :lg="3" class="m-b-5">
          <i-select size="small" placeholder="站点（可通过关键字搜索）" clearable filterable v-model="query.stationId">
            <i-option :value="item.id" v-for="item in stationArray" :key="item.id">{{ assetId === -1 ? `${item.name} -
                        ${item.assetName}` : item.name }}</i-option>
          </i-select>
        </i-col>
        <i-col :xs="24" :sm="12" :md="6" :lg="3" class="m-b-5">
          <i-select size="small" placeholder="资源类型（可通过关键字搜索）" clearable filterable v-model="query.resourcetypeId">
            <OptionGroup v-for="item in resourceTypeArray" :key="'re_parent_' + item.id" :label="item.name">
              <Option v-for="child in item.children" :value="child.id" :key="'re_child' + child.id">{{ child.name }}
              </Option>
            </OptionGroup>
          </i-select>
        </i-col>
        <i-col :xs="24" :sm="12" :md="6" :lg="3" class="m-b-5">
          <i-select size="small" placeholder="资源位置" clearable v-model="query.position">
            <Option v-for="(item,index) in positionArray" :key="index" :value="item.value">{{item.name}}</Option>
          </i-select>
        </i-col>

        <i-col :xs="24" :sm="12" :md="6" :lg="3" class="m-b-5">
          <i-select size="small" placeholder="资源等级" clearable filterable v-model="query.level">
            <Option value="1">A</Option>
            <Option value="2">A+</Option>
            <Option value="3">A++</Option>
            <Option value="4">S</Option>
            <Option value="5">S+</Option>
            <Option value="6">S++</Option>
          </i-select>
        </i-col>

        <i-col :xs="24" :sm="12" :md="6" :lg="3" class="m-b-5">
          <i-select size="small" placeholder="状态" clearable v-model="query.enabled">
            <i-option v-for="item in statusArray" :key="'status_' + item.value" :value="item.value">{{
              item.name }}</i-option>
          </i-select>
        </i-col>

        <i-col :xs="24" :sm="12" :md="4" :lg="4" class="m-b-5">
          <i-input size="small" placeholder="关键字：支持名称查询" v-model="query.keyword"></i-input>
        </i-col>
        <i-col :xs="24" :sm="12" :md="2" :lg="2" class="m-b-5">
          <i-button size="small" class="m-r-5" type="primary" icon="ios-search" @click="handleSearch()">搜索</i-button>
        </i-col>
      </Row>
      <Row :gutter="6" class="m-b-10">
        <i-col span="24">
          <Checkbox v-model="query.filterFix" @on-change="handleSearch">过滤主题媒体内资源</Checkbox>
          <i-button size="small" type="success" @click="handleSettingPrice" :disabled="selectionResourceIds.length<1">添加至媒体商城</i-button>
        </i-col>
      </Row>

      <div>
        <Table size="small" stripe :data="list" :loading="tableLoading" :columns="tableColumns" @on-selection-change="selectionChange"></Table>
        <div class="paging_style">
          <Page size="small" :total="total" :page-size="query.pageSize" show-total show-elevator show-sizer :current="query.pageNumber"
            :page-size-opts="pagesizeSetting" @on-change="handlePageChange" @on-page-size-change='changePageSize'></Page>
        </div>
      </div>
    </Modal>

    <SettingPrice ref="settingPrice" @on-success="handleSettingPriceSearch"/>
  </div>
</template>

<script>
import SettingPrice from './SettingPrice'

import em from '@/utils/enum'
import { getRimStationByAssetIds } from '@/api/rim/station'
import { getPublisherProductAssetList, getResourceTypeList } from '@/api/product/asset'
import { getCloudResource } from '@/api/product/cloudinventory'

export default {
  components: {
    SettingPrice
  },
  data () {
    return {
      showResource: false,

      query: {
        pageNumber: 1,
        pageSize: 15,
        publisherId: this.$store.getters.token.userInfo.publisherId,
        assetId: null,
        enabled: null,
        position: null,
        resourcetypeId: null,
        stationId: null,
        keyword: '',
        level: null,
        filterFix: true, // 是否过滤主题媒体内资源
        cloud: 0 // 取全部未加入媒体库的资源
      },
      list: [],
      total: 0,
      pagesizeSetting: [15, 50, 100, 200],

      assetId: -1, // 用于处理选择全部资产时的查询参数
      assetArray: [],
      stationArray: [],
      positionArray: em.positionEnum,
      resourceTypeArray: [],
      statusArray: [
        { value: 1, name: '可用' },
        { value: 0, name: '不可用' },
        { value: -1, name: '冻结' }
      ],
      tableColumns: [
        { type: 'selection', width: 60, align: 'center', fixed: 'left' },
        { title: '编号', align: 'center', key: 'code' },
        { title: '站点', align: 'center', key: 'stationName' },
        { title: '类型', align: 'center', key: 'resourcetypeName' },
        { title: '位置', align: 'center', key: 'positionName', width: 90 },
        { title: '等级', align: 'center', key: 'levelName', width: 80 },
        { title: '楼层', align: 'center', key: 'floor', width: 80 },
        { title: '面积(㎡)', align: 'center', key: 'area', width: 80 },
        {
          title: '制作尺寸(mm)',
          align: 'center',
          width: 110,
          render: (h, params) => {
            return h('span', `${params.row.makewidth} * ${params.row.makeheight}`)
          }
        },
        {
          title: '见光尺寸(mm)',
          align: 'center',
          width: 110,
          render: (h, params) => {
            return h('span', `${params.row.finalwidth} * ${params.row.finalheight}`)
          }
        },
        {
          title: '状态',
          align: 'center',
          key: 'enabled',
          width: 80,
          render: (h, params) => {
            const status = this.statusArray.find(x => x.value === params.row.enabled)
            return h('span', status ? status.name : '/')
          }
        }
      ],

      tableLoading: false,
      selectionResourceIds: [],
      hasElectronic: false
    }
  },
  methods: {
    showModal () {
      this.showResource = true
      this.getPublisherAssetData()
    },
    initData () {
      this.tableLoading = true
      getCloudResource(this.query).then(res => {
        if (res && !res.errcode) {
          this.list = res.records
          this.total = res.total
          this.query.pageNumber = res.current
        } else {
          this.list = []
          this.total = 0
          this.query.pageNumber = 1
        }

        this.tableLoading = false
      })
    },
    getPublisherAssetData () {
      getPublisherProductAssetList().then(res => {
        if (res && !res.errcode) {
          this.assetArray = res
        }

        this.initStationArray()
        this.getResourceTypeData()
        this.initData()
      })
    },
    // 初始化站点选项
    initStationArray () {
      this.query.stationId = null
      let assetIds = []
      if (this.assetId <= -1) {
        this.assetArray.forEach(item => {
          assetIds = assetIds.concat(this.assemblyRimAssetIds(item.rimAssetIds))
        })
      } else {
        const rimAsset = this.assetArray.find(x => x.id === this.assetId)
        if (rimAsset) {
          assetIds = this.assemblyRimAssetIds(rimAsset.rimAssetIds)
        }
      }
      // 调用mixins中的方法
      getRimStationByAssetIds({ assetIds: JSON.stringify(assetIds) }).then((res) => {
        this.stationArray = res
      })
    },
    assemblyRimAssetIds (data) {
      const ids = []
      if (data.indexOf(',')) {
        data.split(',').forEach(x => {
          ids.push(parseInt(x))
        })
      } else {
        ids.push(parseInt(data))
      }
      return ids
    },
    getResourceTypeData () {
      const assetIds = this.assetId === -1 ? this.assetArray.map(x => x.id) : [this.assetId]
      getResourceTypeList({ assetIds: JSON.stringify(assetIds) }).then(res => {
        if (res && !res.errcode) {
          this.resourceTypeArray = res
        } else {
          this.resourceTypeArray = []
        }
      })
    },
    handleSearch () {
      this.query.pageNumber = 1
      this.selectionResourceIds = []
      this.initData()
    },
    handleSettingPriceSearch () {
      this.handleSearch()
      this.$emit('on-success')
    },
    handleChangeAsset () {
      this.query.assetId = this.assetId === -1 ? undefined : this.assetId
      this.initStationArray()
      this.getResourceTypeData()
      this.handleSearch()
    },
    handlePageChange (curPage) {
      this.query.pageNumber = curPage
      this.initData()
    },
    changePageSize (pageSize) {
      this.query.pageSize = pageSize
      this.query.pageNumber = 1
      this.initData()
    },
    selectionChange (selection) {
      this.hasElectronic = selection.filter(x => x.resourcetypeId > 300 & x.resourcetypeId < 400).length > 0
      const resourceIds = selection.map(x => x.id)
      // 判断是否存在已有的数据，否则移除

      this.selectionResourceIds = resourceIds
    },
    handleSettingPrice () {
      const params = {
        hasElectronic: this.hasElectronic,
        selectionResourceIds: this.selectionResourceIds
      }

      this.$refs.settingPrice.showModal(params)
    }
  }
}
</script>
